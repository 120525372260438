import { template as template_23a7490740d74603a3592adaf2e260cd } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_23a7490740d74603a3592adaf2e260cd(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
