import { template as template_cbea5f54b6c54a49a65a837e1415b05d } from "@ember/template-compiler";
const WelcomeHeader = template_cbea5f54b6c54a49a65a837e1415b05d(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
