import { template as template_53ebd71c6f2e4602b38ce4a4a6727798 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_53ebd71c6f2e4602b38ce4a4a6727798(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
